import React from "react"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Carousel from "../components/Carousel"
import Body from "../components/Body"
import formatDates from "../utils/formatDates"
// import SEO from "../components/seo"

const EventTemplate = ({ data, pageContext, location }) => {

  const { event, insta } = data
  const { localFile = {}, carouselImages = [] } = insta || {}
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  // if carouselImages exisits use it over localFile
  let images
  if (carouselImages.length > 0) {
    images = carouselImages.map(({ localFile }) => getImage(localFile))
  } else if (localFile) {
    images = getImage(localFile)
  }

  const { displayDate: currentDisplayDate } = formatDates(event.start, event.end)

  return (
    <React.Fragment>
      <article>
        <header>
          <h1>
            {currentDisplayDate}
          </h1>
        </header>
        <Body value={event._rawBody} />
        <Carousel images={images} />
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.slug.current} rel="prev">
                  ← Prev
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.slug.current} rel="next">
                  Next →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </article>
    </React.Fragment>
  )
}

export default EventTemplate

export const pageQuery = graphql`
  query EventBySlug($id: String!, $hashtag: String!) {
    site {
      siteMetadata {
        title
      }
    }
    event: sanityEvent(id: { eq: $id }) {
      id
      _rawBody(resolveReferences: {maxDepth: 10})
      startDate
      endDate
      address
    }
    insta: instaNode(hashtags: {in: [$hashtag] }) {
      id
      hashtags
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 1080
            outputPixelDensities: [0.5, 1, 2]
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      carouselImages {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1080
              outputPixelDensities: [0.5, 1, 2]
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`